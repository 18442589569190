import { AiOutlineException } from "react-icons/ai";
import { BiPurchaseTag, BiPurchaseTagAlt } from "react-icons/bi";
import { BsCreditCard } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { GiAchievement, GiRoad } from "react-icons/gi";
import { SiBaremetrics } from "react-icons/si";

const dashboardConfig = {
  sk1: {
    customFeatures: [
      {
        name: "Fuel Prices",
        icon: <FaMoneyBillAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-prices",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiZTM2NmU1YmMtNDUwOS00ZmFmLTk4OWMtNjExMDk4NjNmYWM1IiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9",
      },
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiZmViYjBiZWUtYzI5MS00ZDk2LThjOGEtMTQxOTJmOWQyYWIyIiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9&pageName=ReportSection",
      },
      {
        name: "Purchase Exceptions",
        icon: <AiOutlineException className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/purchase-exceptions",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiZmViYjBiZWUtYzI5MS00ZDk2LThjOGEtMTQxOTJmOWQyYWIyIiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9&pageName=ReportSection85857afba7e6b5a06216",
      },
      {
        name: "Fuel Cards",
        icon: <BsCreditCard className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-cards",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNjMwN2Y0ZmYtZDYyZS00NDY5LWJjMzEtYTc0MGVlM2YzMzQzIiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9",
      },
      {
        name: "Miles Per Gallon",
        icon: <GiRoad className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/mpg",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNDMzNzgwNWQtN2ZmMy00MzNmLTlkYzctNzlkOTc1MWEzYjQ2IiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9",
      },
      {
        name: "Goals",
        icon: <GiAchievement className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/goals",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiM2I2NmM0YjMtZDgwOC00OGQ2LWFkMGYtNjA0ZTk5ZjlkNTI2IiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9",
      },
    ],
    isEmailAvailable: true,
  },
  mnz: {
    customFeatures: [
      {
        name: "Fuel Prices",
        icon: <FaMoneyBillAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-prices",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiODEzODM1MzAtZTEyMS00ZTBkLWI2MTEtMmFkZGU5NjI5ZDVjIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiM2ZjNjM2MmItODVkZC00N2M3LWE1ODQtYTJmMjRiZThjMWI2IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Plans Metrics",
        icon: <SiBaremetrics className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-plan-metrics",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiOGJlOTI5Y2UtMmNhYy00MjQ2LWE5OWEtZDIxM2RhN2M0YjI1IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
    ],
    isEmailAvailable: true,
  },
  ist: {
    customFeatures: [
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiOTRmYTZkNTctMWJiZC00OWRlLTkxMTktZjNhYzM3NzExMzA2IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
    ],
    isEmailAvailable: true,
  },
  we1: {
    customFeatures: [
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNmQ5M2RmOTctNzUwYi00MTdmLTk1MmItN2UxOGI1N2RmM2FmIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
    ],
    isEmailAvailable: true,
  },
  demo: {
    customFeatures: [
      {
        name: "Fuel Prices",
        icon: <FaMoneyBillAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-prices",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiMzk1MTJkODQtMmQwYy00M2NmLWE3MzMtM2Y0OGE3MWU5MWY4IiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9",
      },
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiOTY0NzQzYzQtZDdmZC00NjdkLWFjZjUtM2YxMzdlOTkxYWNhIiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9",
      },
      {
        name: "Purchase Exceptions",
        icon: <AiOutlineException className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/purchase-exceptions",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiOTY0NzQzYzQtZDdmZC00NjdkLWFjZjUtM2YxMzdlOTkxYWNhIiwidCI6ImE2OTBjMThjLTcyNDYtNDhhNi1iMDUyLTYwMDk2MDg3ZjI2YiIsImMiOjN9&pageName=ReportSection85857afba7e6b5a06216",
      },
    ],
    isEmailAvailable: true,
  },
  trl: {
    customFeatures: [],
    isEmailAvailable: true,
  },
  gx3: {
    customFeatures: [
      {
        name: "Fuel Prices",
        icon: <FaMoneyBillAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-prices",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiMzI5OGE1YzctMjdmYS00ODA3LWI2NzYtMWI5ODQzZWQ2ZjI3IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9&pageName=ReportSection607a36c61c8fbe7592c3",
      },
    ],
    isEmailAvailable: true,
  },
  rvn: {
    customFeatures: [
      {
        name: "Fuel Plans Metrics",
        icon: <SiBaremetrics className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-plan-metrics",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiZTVjNDM2M2UtZTVjYy00MDdlLWFiYzktM2ZhMmQ1MzNhZTJkIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTagAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiMjdlMjNjZDUtMGYyYi00MjliLTgzMTgtY2JkZTFjMTNlOTE0IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9&pageName=ReportSectionbee3c452ace4d0737847",
      },
    ],
    isEmailAvailable: true,
  },
  anr: {
    customFeatures: [
      {
        name: "Fuel Prices",
        icon: <FaMoneyBillAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-prices",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNzkxYzEzMmQtM2MwYS00NjE5LWE1NmQtMmRlOWQzZTYzMDU1IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNGNlYTEwMDUtMzA1NC00N2NhLTg2NTMtNDEzZWNiZjkxZDlhIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Plans Metrics",
        icon: <SiBaremetrics className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-plan-metrics",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNGU5YjY1ODUtZDk0NS00YzU4LTlmNjYtYjdkNTgwZTdhOGUxIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
    ],
    isEmailAvailable: true,
  },
  cls1: {
    customFeatures: [],
    isEmailAvailable: true,
  },
  mlh: {
    customFeatures: [
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNzA0MjEwMjEtMjEyMC00MjdmLWIyOTktNzZhYjBjMDJkMTdkIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
    ],
    isEmailAvailable: true,
  },
  mlg: {
    customFeatures: [],
    isEmailAvailable: [],
  },
  knm: {
    customFeatures: [],
    isEmailAvailable: [],
  },
  lnr: {
    customFeatures: [],
    isEmailAvailable: [],
  },
  cnl: {
    customFeatures: [
      {
        name: "Fuel Prices",
        icon: <FaMoneyBillAlt className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-prices",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiY2MzZmQ0YzMtNmUyZC00YjM0LWJlNTEtNTA0OTc2Mjg1ZGIzIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Purchases",
        icon: <BiPurchaseTag className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-purchases",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiNzRkYjRkOWQtMjIwYy00YzExLWFlNTUtNGM0M2FkYzBkZmFiIiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
      {
        name: "Fuel Plans Metrics",
        icon: <SiBaremetrics className="flex-shrink-0 h-6 w-6 mr-3 text-gray-400" />,
        route: "/fuel-plan-metrics",
        iframeUrl:
          "https://app.powerbi.com/view?r=eyJrIjoiZDkzNDU0Y2MtYTU2Ny00YTc5LWIwMWQtMWM2MzhjODFkMTU4IiwidCI6ImQxYmI3MjcyLTIwMTUtNDBjYS04ZTVlLTk1YjEwZWU1ODg4YSJ9",
      },
    ],
    isEmailAvailable: true,
  },
  alt: {
    customFeatures: [],
    isEmailAvailable: [],
  },
};

export default dashboardConfig;
