import BlockContent from "@sanity/block-content-to-react";
// import imageUrlBuilder from "@sanity/image-url";
import cn from "classnames";

import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import styles from "./blog.module.css";

// const builder = imageUrlBuilder(sanityClient);
// function urlFor(source) {
// 	return builder.image(source);
// }

export default function OnePost() {
	const [postData, setPostData] = useState(null);
	const { slug } = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getPost = async () => {
			const [post] = await sanityClient.fetch(
				`*[slug.current == $slug]{
						title,
						slug,
						mainImage{
							asset->{
							_id,
							url
							}
						},
						body,
						"name": author->name,
						"authorImage": author->image
					   }`,
				{ slug }
			);
			setLoading(false);
			setPostData(post);
		};
		getPost();
	}, [slug]);

	return (
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `main {
                        overflow-y: auto;
                    }`,
				}}
			/>
			{loading ? (
				<>
					<div alt="" className="skeleton-animation h-96 bg-gray-600" />
					<div
						className={cn("bg-white max-w-screen-md w-full mx-auto px-10 pt-10 pb-20 -mt-40 flex flex-col")}
					>
						<div className="skeleton-animation text-4xl font-bold text-center mt-4 mb-8 h-10 w-full sm:w-[450px] self-center rounded-lg"></div>
						<div className="mb-6">
							<div className="skeleton-animation w-full h-[27px] rounded-lg mb-3"></div>
							<div className="skeleton-animation w-11/12 h-[27px] rounded-lg mb-3"></div>
							<div className="skeleton-animation w-11/12 h-[27px] rounded-lg mb-3"></div>
							<div className="skeleton-animation w-9/12 h-[27px] rounded-lg mb-3"></div>
						</div>
						<div className="mb-6">
							<div className="skeleton-animation w-full h-[27px] rounded-lg mb-3"></div>
							<div className="skeleton-animation w-11/12 h-[27px] rounded-lg mb-3"></div>
							<div className="skeleton-animation w-11/12 h-[27px] rounded-lg mb-3"></div>
							<div className="skeleton-animation w-9/12 h-[27px] rounded-lg mb-3"></div>
						</div>
					</div>
				</>
			) : (
				<>
					<div
						style={{
							backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${postData.mainImage.asset.url})`,
						}}
						alt=""
						className="h-96 bg-cover bg-no-repeat bg-center p-8 flex flex-col justify-end mb-10"
					/>
					<div className={cn("bg-white max-w-screen-md w-full mx-auto px-10 pt-10 pb-20 -mt-40")}>
						<h1 className="text-4xl font-bold text-center mb-8">{postData.title}</h1>
						<BlockContent
							blocks={postData.body}
							projectId={sanityClient.clientConfig.projectId}
							dataset={sanityClient.clientConfig.dataset}
							className={styles.body}
						/>
					</div>
				</>
			)}
		</>
	);
}
