// @ts-nocheck
import "leaflet/dist/leaflet.css";
import { useMemo } from "react";
import {
  Circle,
  MapContainer,
  // Popup,
  TileLayer,
} from "react-leaflet";
import { useTable } from "react-table";
import CsvDownloadButton from "react-json-to-csv";

const TractorFuelStopsCompliance = ({ data, onClose }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Solution Date",
        accessor: (row) => new Date(row.SolutionDate).toLocaleDateString("en-US"),
      },
      {
        Header: "Segment",
        accessor: "Segment",
      },
      {
        Header: "Tractor",
        accessor: "Tractor",
      },
      {
        Header: "Lane",
        accessor: (row) => `${row.TractorOrigin} - ${row.TractorDestination}`,
      },
      {
        Header: "Station",
        accessor: "Station",
      },
      {
        Header: "Score",
        accessor: (row) => (row.compliance === 1 ? 100 : 0),
        Cell: ({ value }) => `${value}%`,
      },
      {
        Header: "Optimal PPG",
        accessor: "OptimalPPG",
      },
    ],
    []
  );

  const tableData = useMemo(() => data.fuelStops, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: tableData,
  });

  console.log("tableData", tableData);

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mt-2 text-center sm:text-left">
              <CsvDownloadButton
                className="bg-green-600 text-white px-2 rounded absolute top-1 left-6"
                data={tableData}
                headers={[
                  "SolutionDate",
                  "Cust",
                  "Segment",
                  "Tractor",
                  "TractorOrigin",
                  "TractorDestination",
                  "Station",
                  "compliance",
                  "City",
                  "State",
                  "Latitude",
                  "Longitude",
                  "store_number",
                  "OptimalPPG",
                ]}
                delimiter=","
              />
              <button onClick={onClose} className="absolute top-0.5 right-4 font-medium text-xl cursor-pointer">
                x
              </button>
              {/* Map */}
              <div className="overflow-hidden">
                <MapContainer center={[37.09, -95.71]} zoom={4} style={{ height: "400px" }}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {tableData.map((fuelStop, i) => (
                    <Circle
                      key={`${fuelStop.Station}-${i}`}
                      center={[fuelStop.Latitude, fuelStop.Longitude]}
                      fillColor={fuelStop.compliance === 1 ? "green" : "red"}
                      color={fuelStop.compliance === 1 ? "green" : "red"}
                      radius={50000}
                    >
                      {/* <Popup>
                        <div>
                          <p>Station: {fuelStop.Station}</p>
                          <p>Compliance: {fuelStop.compliance === 1 ? "Compliant" : "Non-compliant"}</p>
                        </div>
                      </Popup> */}
                    </Circle>
                  ))}
                </MapContainer>
              </div>
              <div className="mt-2 max-h-60 overflow-auto">
                <table className="relative min-w-full divide-y divide-gray-200" {...getTableProps()}>
                  <thead className="bg-gray-50">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            className="bg-gray-50 sticky top-0 group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            {column.canFilter ? column.render("Filter") : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TractorFuelStopsCompliance;
