import Blog from "pages/blog";
import Blogs from "pages/blogs";
import BotsPage from "pages/bots";
import LandingPage from "pages/landing-page/landing-page";
import { Navigate, Route, Routes } from "react-router-dom";

const UnAuthApp = () => {
	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="/bots/:id" element={<BotsPage />} />
			<Route path="/blogs">
				<Route index element={<Blogs />} />
				<Route path=":slug" element={<Blog />} />
			</Route>
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};

export default UnAuthApp;
