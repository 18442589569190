// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import * as api from "api";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { AiFillCaretDown, AiTwotoneCalendar } from "react-icons/ai";
import { CellProps, Column, ColumnInstance, useFilters, useSortBy, useTable } from "react-table";
import TractorFuelStopCompliance from "./tractor-fuel-stop-compliance";
import CsvDownloadButton from "react-json-to-csv";

interface FuelStop {
  SolutionDate: string | null;
  Cust: string | null;
  Segment: number | null;
  Tractor: string | null;
  TractorOrigin: string | null;
  TractorDestination: string | null;
  Station: string | null;
  compliance: 0 | 1 | null;
  City: string | null;
  State: string | null;
  Latitude: number | null;
  Longitude: number | null;
  store_number: string | null;
  OptimalPPG: number | null;
}

interface TractorComplianceData {
  tractor: string;
  fuelStops: FuelStop[];
}

type CustomerTractorComplianceResponseData = {
  averageCompliancePercent: number;
  tractorFuelStops: {
    tractor: string;
    fuelStops: FuelStop[];
  }[];
};

type CustomerTractorComplianceResponse = {
  status: string;
  data: CustomerTractorComplianceResponseData;
};

let initialRender = true;

function TractorComplianceTable() {
  const [complianceForExport, setComplianceForExport] = useState([]);
  const { user } = useAuth0();
  const [customerComplienceDataLoading, setCustomerComplienceDataLoading] = useState(true);
  const [customerComplienceData, setCustomerComplienceData] = useState<CustomerTractorComplianceResponseData | null>(
    null
  );
  const [selectedRowData, setSelectedRowData] = useState<TractorComplianceData | null>(null);
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));

  interface CustomColumnInstance<D extends object> extends ColumnInstance<D> {
    filterValue?: string;
    setFilter: (value?: string) => void;
  }

  type CustomFilterProps<D extends object, C extends CustomColumnInstance<D>> = {
    column: C;
  };

  const columns: Column<TractorComplianceData>[] = useMemo(
    () => [
      {
        Header: "Tractor",
        accessor: "tractor",
        Filter: ({ column }: CustomFilterProps<TractorComplianceData, CustomColumnInstance<TractorComplianceData>>) => {
          const { filterValue, setFilter } = column;
          return (
            <div>
              <input
                type="text"
                value={filterValue || ""}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={`Search ${column.Header}`}
              />
            </div>
          );
        },
      },
      {
        Header: "Total Fuel Stops",
        accessor: (row) => (row.fuelStops ? row.fuelStops.length : 0),
        Cell: ({ row, value }: CellProps<TractorComplianceData, number>) => (
          <div
            className="cursor-pointer underline"
            onClick={() => {
              setSelectedRowData(row.original);
            }}
          >
            {value}
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Compliant Fuel Stops",
        accessor: (row) => row.fuelStops.filter((stop) => stop.compliance === 1).length,
        disableFilters: true,
      },
      {
        Header: "Non Compliant Fuel Stops",
        accessor: (row) => row.fuelStops.filter((stop) => stop.compliance === 0).length,
        disableFilters: true,
      },
      {
        Header: "Compliance",
        accessor: (row) => {
          const compliantFuelStops = row.fuelStops.filter((fuelStop) => fuelStop.compliance === 1);

          const fuelComplienePercent = Math.round((compliantFuelStops.length / row.fuelStops.length) * 100);

          return fuelComplienePercent;
        },
        Cell: ({ value }: CellProps<TractorComplianceData, number>) => {
          return (
            <div
              className={classNames("px-2 py-1 text-center rounded w-14", {
                "bg-green-300": value >= 80,
                "bg-yellow-300": value >= 60 && value < 80,
                "bg-red-300": value < 60,
              })}
            >
              {value}%
            </div>
          );
        },
        disableFilters: true,
        disableSortBy: false,
      },
    ],
    []
  );

  const tableData = useMemo(() => customerComplienceData?.tractorFuelStops ?? [], [customerComplienceData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: "Compliance",
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    function prepareComplianceForExport(data: CustomerTractorComplianceResponseData) {
      return data.tractorFuelStops.map((compliance) => {
        const compliantFuelStops = compliance.fuelStops.filter((fuelStop) => fuelStop.compliance === 1);
        const nonCompliantFuelStops = compliance.fuelStops.filter((fuelStop) => fuelStop.compliance === 0);
        const compliancePercent = Math.round((compliantFuelStops.length / compliance.fuelStops.length) * 100);

        return {
          tractor: compliance.tractor,
          numberOfFuelStops: compliance.fuelStops.length,
          numberOfCompliantFuelStops: compliantFuelStops.length,
          numberOfNonCompliantFuelStops: nonCompliantFuelStops.length,
          compliancePercent,
        };
      });
    }

    const getCustomerFuelPlanComplienceAsync = async () => {
      if (!initialRender) setCustomerComplienceDataLoading(true);
      if (initialRender) initialRender = false;
      const { data }: { data: CustomerTractorComplianceResponse } = await api.getCustomerFuelPlanComplience(
        user!["https://ifuelsmart.com/company"],
        startDate.toISOString().substring(0, 10),
        endDate.toISOString().substring(0, 10)
      );

      const complianceForExport = prepareComplianceForExport(data.data);
      setComplianceForExport(complianceForExport);

      setCustomerComplienceData(data.data);
      setCustomerComplienceDataLoading(false);
    };

    getCustomerFuelPlanComplienceAsync();
  }, [user, startDate, endDate]);

  return (
    <>
      <div className="h-full flex flex-col">
        {selectedRowData && (
          <TractorFuelStopCompliance onClose={() => setSelectedRowData(null)} data={selectedRowData} />
        )}

        <div className="flex items-center mb-4 h-14">
          <div className="relative mr-4">
            <label className="block font-medium mb-1 text-sm" htmlFor="datepicker">
              Start Date
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              id="datepicker"
              name="datepicker"
              className="bg-white rounded shadow py-2 px-3"
            />
            <div className="absolute top-9 right-4">
              <AiTwotoneCalendar className="text-gray-400" />
            </div>
          </div>
          <div className="relative">
            <label className="block font-medium mb-1 text-sm" htmlFor="datepicker">
              End Date
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy"
              id="datepicker"
              name="datepicker"
              className="bg-white rounded shadow py-2 px-3"
            />
            <div className="absolute top-9 right-4">
              <AiTwotoneCalendar className="text-gray-400" />
            </div>
          </div>

          <div className="flex ml-auto space-x-2">
            <CsvDownloadButton
              disabled={customerComplienceDataLoading}
              className="bg-green-600 text-white px-2 rounded hover:bg-green-500 transition-colors disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed"
              data={complianceForExport}
              headers={[
                "Tractor",
                "Number of Fuel Stops",
                "Number of Compliant Fuel Stops",
                "Number of Non Compliant Fuel Stops",
                "Compliance Percent",
              ]}
              delimiter=","
            >
              Export Compliance
            </CsvDownloadButton>
            {customerComplienceDataLoading ? (
              <div
                className={classNames("ml-auto inline-flex flex-col items-center bg-slate-300 rounded px-3 py-2", {})}
              >
                <div className="text-xs font-medium mb-1">Avg. Compliance</div>
                <svg
                  className="animate-spin h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <div
                className={classNames("ml-auto inline-flex flex-col items-center bg-green-300 rounded px-3 py-2", {
                  "bg-green-300": customerComplienceData!.averageCompliancePercent >= 80,
                  "bg-yellow-300":
                    customerComplienceData!.averageCompliancePercent >= 60 &&
                    customerComplienceData!.averageCompliancePercent < 80,
                  "bg-red-300": customerComplienceData!.averageCompliancePercent < 60,
                })}
              >
                <div className="text-xs font-medium mb-1">Avg. Compliance</div>
                <div className="text-sm font-bold">{Math.round(customerComplienceData!.averageCompliancePercent)}%</div>
              </div>
            )}
          </div>
        </div>

        <div className="overflow-y-auto flex-1">
          <div className="overflow-x-auto">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="relative min-w-full divide-y divide-gray-200" {...getTableProps()}>
                  <thead className="bg-gray-50">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            className="sticky top-0 group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            {...column.getHeaderProps({
                              ...column.getSortByToggleProps(),
                              onClick: () => {
                                if (!column.canSort) return;
                                if (!column.isSorted) {
                                  column.toggleSortBy(false, false);
                                } else {
                                  column.toggleSortBy(!column.isSortedDesc, false);
                                }
                              },
                            })}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <AiFillCaretDown className="inline-block text-base" />
                                ) : (
                                  <AiFillCaretDown className="inline-block text-base rotate-180" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                            {column.canFilter ? column.render("Filter") : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
                    {customerComplienceDataLoading && (
                      <tr>
                        <td colSpan={5} className="py-4">
                          <svg
                            className="animate-spin h-7 w-7 mx-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                    )}
                    {!customerComplienceDataLoading &&
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TractorComplianceTable;
