import Geosuggest, { Suggest } from "react-geosuggest"; // I installed types. Uninstall when migrating way from this
import { AiFillExclamationCircle } from "react-icons/ai";
import "./location-searcher.css";

interface LocationSearcherProps {
  label: string;
  placeholder: string;
  required: boolean;
  onSuggestSelect: (locationInformation: Suggest) => {};
  initialValue: string;
  error: string;
  value: string;
}

const LocationSearcher = ({
  label,
  placeholder,
  required,
  onSuggestSelect,
  initialValue,
  error,
}: LocationSearcherProps) => {
  return (
    <div className="relative mb-4 w-full">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
        {label}
      </label>
      <Geosuggest
        placeholder={placeholder || "Enter location"}
        autoComplete="off"
        // country="us"
        country={["us", "ca"]}
        types={["(regions)"]}
        required={required}
        ignoreTab
        ignoreEnter
        onSuggestSelect={(place) => onSuggestSelect(place)}
        initialValue={initialValue}
      />
      {error && (
        <div className="text-red-600 mt-1 flex items-center gap-1">
          <AiFillExclamationCircle />
          <span className="text-sm font-medium">{error}</span>
        </div>
      )}
    </div>
  );
};

export default LocationSearcher;
