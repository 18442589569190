import { useAuth0 } from "@auth0/auth0-react";
import { getFuelLevelForDropdownTractors, getTractorsForDropdown } from "api";
import { createContext, useContext, useEffect, useState } from "react";

const TractorsContext = createContext();

const useTractors = () => {
  const context = useContext(TractorsContext);
  if (context === "undefined") throw new Error("useTractors must be used within TractorsProvider");
  return context;
};

const TractorsProvider = ({ children }) => {
  const { user } = useAuth0();
  const [tractors, setTractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [updatedAt, setUpdatedAt] = useState(null);

  const mapTractorsToFuelLevel = (tractorsList, fuelLevelsList) => {
    const tractorFuelLookup = fuelLevelsList.reduce((acc, curr) => {
      acc[curr.name] = curr;
      return acc;
    }, {});

    const tractorsWithFuelLevel = tractorsList.map((tractor) => {
      const tractorFuelLevelInformation = tractorFuelLookup[tractor.unit_number];

      if (tractorFuelLevelInformation) {
        return {
          ...tractor,
          fuelLevelInGallons: Math.round((tractorFuelLevelInformation.fuel_percent / 100) * tractor.tank_capacity),
        };
      }

      return { ...tractor, fuelLevelInGallons: null };
    });

    return tractorsWithFuelLevel;
  };

  useEffect(() => {
    (async () => {
      try {
        const [tractorsResponse, fuelLevelsResponse] = await Promise.all([
          getTractorsForDropdown(user["https://ifuelsmart.com/company"]),
          getFuelLevelForDropdownTractors(user["https://ifuelsmart.com/company"]),
        ]);

        const tractorFuelLookup = fuelLevelsResponse.data.reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, {});

        const tractorsWithFuelLevel = tractorsResponse.data.map((tractor) => {
          const tractorFuelLevelInformation = tractorFuelLookup[tractor.unit_number];

          if (tractorFuelLevelInformation) {
            return {
              ...tractor,
              fuelLevelInGallons: Math.round((tractorFuelLevelInformation.fuel_percent / 100) * tractor.tank_capacity),
            };
          }

          return { ...tractor, fuelLevelInGallons: null };
        });

        setTractors(tractorsWithFuelLevel);
        setInitialLoading(false);
        setUpdatedAt(new Date());
      } catch (e) {
        setInitialLoading(false);
      }
    })();

    const TIME_TILL_REFRESH_REFRESH = 300_000;

    const handleRefresh = async () => {
      const [tractorsResponse, fuelLevelsResponse] = await Promise.all([
        getTractorsForDropdown(user["https://ifuelsmart.com/company"]),
        getFuelLevelForDropdownTractors(user["https://ifuelsmart.com/company"]),
      ]);

      setTractors(mapTractorsToFuelLevel(tractorsResponse.data, fuelLevelsResponse.data));

      setTimeout(handleRefresh, TIME_TILL_REFRESH_REFRESH);
      setUpdatedAt(new Date());
    };

    setTimeout(handleRefresh, TIME_TILL_REFRESH_REFRESH);
  }, [user]);

  async function getTractors() {
    try {
      setLoading(true);

      const [tractorsResponse, fuelLevelsResponse] = await Promise.all([
        getTractorsForDropdown(user["https://ifuelsmart.com/company"]),
        getFuelLevelForDropdownTractors(user["https://ifuelsmart.com/company"]),
      ]);

      setTractors(mapTractorsToFuelLevel(tractorsResponse.data, fuelLevelsResponse.data));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const value = {
    loading,
    tractors,
    getTractors,
    updatedAt,
  };

  return (
    <TractorsContext.Provider value={value}>
      {!initialLoading && children}
      {initialLoading && (
        <div className="h-screen bg-gray-800 grid place-items-center text-white">
          <div className="flex flex-col items-center px-8 max-w-xs md:max-w-lg text-center">
            <svg
              className="animate-spin -ml-1 mr-3 h-9 w-9"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <h2 className="text-xl font-semibold mt-3">Loading...</h2>
            <p className="mt-1">Retrieving tractor, please don't close this page.</p>
          </div>
        </div>
      )}
    </TractorsContext.Provider>
  );
};

export { TractorsProvider, useTractors };
