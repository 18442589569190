import { useAuth0 } from "@auth0/auth0-react";
import logoOrange from "assets/logo-orange.png";
import logoWhite from "assets/logo-white.png";
import cn from "classnames";
import { Link } from "react-router-dom";

const Header = ({ type = "white" }) => {
  const { loginWithRedirect } = useAuth0();

  const { user } = useAuth0();

  return (
    <header className={cn({ "bg-white": type === "white" }, { "bg-orange-600": type === "orange" })}>
      <div className="flex justify-between max-w-screen-xl px-6 py-4 mx-auto lg:px-8 xl:px-4 lg:py-6">
        <Link to="/">
          <span className="sr-only">SaaS landing page</span>
          {type === "white" && <img src={logoOrange} alt="" srcSet="" className="w-40 sm:w-[180px]" />}
          {type === "orange" && <img src={logoWhite} alt="" srcSet="" className="w-40 sm:w-[180px]" />}
        </Link>
        <nav
          className={cn("flex items-center space-x-7", {
            "text-gray-800": type === "white",
            "text-white": type === "orange",
          })}
        >
          <Link to="/blogs" className="font-bold">
            Blog
          </Link>
          <button
            type="button"
            className={cn("inline-block px-5 py-2 font-bold rounded-lg", {
              "bg-white text-orange-600": type === "orange",
              "bg-orange-600 text-white": type === "white",
            })}
            onClick={loginWithRedirect}
          >
            {user ? "App" : "Sign In"}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
