import { InfoWindow, Marker, Polyline } from "@react-google-maps/api";
import MarkerIcon from "assets/gas-marker.svg";
import HomeMarker from "assets/gray-marker.svg";
// import Directions from "components/map/directions";
import FuelOptimizerLayout from "layouts/fuel-optimizer-layout";
import { useState } from "react";
import Form from "./form";
import FuelPlan from "./fuel-plan";
import Map from "./map";

const FuelOptimzer = () => {
  const [fuelPlan, setFuelPlan] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [center, setCenter] = useState({ lat: 31.7619, lng: -106.485 });

  const markerLoadHandler = (marker, location) => {
    return setMarkerMap((prevState) => ({ ...prevState, [location.loc_id]: marker }));
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) setInfoOpen(false);

    setInfoOpen(true);
  };

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
  };

  const resetFuelPlan = () => {
    setFuelPlan(null);
    setMarkerMap({});
    setSelectedPlace(null);
    setInfoOpen(false);
    setCenter({ lat: 31.7619, lng: -106.485 });
  };

  return (
    <>
      <FuelOptimizerLayout
        leftPanel={
          !fuelPlan ? (
            <Form setFuelPlan={setFuelPlan} />
          ) : (
            <>{<FuelPlan fuelPlan={fuelPlan} setFuelPlan={setFuelPlan} resetFuelPlan={resetFuelPlan} />}</>
          )
        }
        map={
          <Map
            onLoad={loadHandler}
            center={center}
            onCenterChanged={() => {
              if (mapRef) setCenter(mapRef.getCenter().toJSON());
            }}
          >
            {!Array.isArray(fuelPlan) && fuelPlan ? (
              <>
                <Marker
                  position={{ lat: fuelPlan.origin.lat, lng: fuelPlan.origin.lng }}
                  icon={{
                    url: HomeMarker,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />
                <Marker
                  position={{ lat: fuelPlan.destination.lat, lng: fuelPlan.destination.lng }}
                  icon={{
                    url: HomeMarker,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />

                <Polyline
                  path={fuelPlan.decodedPolyline}
                  options={{
                    strokeColor: "#047857",
                    strokeOpacity: 0.8,
                    strokeWeight: 4,
                    fillColor: "#047857",
                    fillOpacity: 0.35,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    zIndex: 1,
                  }}
                />

                {fuelPlan.fuelPurchaseLocations.map((location) => {
                  if (!location.active) return null;

                  return (
                    <>
                      <Marker
                        key={JSON.stringify(location)}
                        position={{ lat: location.lat, lng: location.lng }}
                        onLoad={(marker) => markerLoadHandler(marker, location)}
                        onClick={(event) => {
                          markerClickHandler(event, location);
                        }}
                        icon={{
                          url: MarkerIcon,
                          scaledSize: new window.google.maps.Size(30, 30),
                        }}
                      />
                      {infoOpen && selectedPlace?.loc_id === location.loc_id && (
                        <InfoWindow anchor={markerMap[selectedPlace.loc_id]} onCloseClick={() => setInfoOpen(false)}>
                          <div>
                            <p className="font-medium mb-0.5">{selectedPlace.location}</p>
                            <p className="mb-0.5">{selectedPlace.interstate_exit}</p>
                            <p className="">
                              {selectedPlace.fuelToPurchase === -99
                                ? "Top off tractor at"
                                : `Purchase ${selectedPlace.fuelToPurchase} gallons at`}
                              &nbsp;
                              {selectedPlace.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}{" "}
                              / gal
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    </>
                  );
                })}
              </>
            ) : null}
          </Map>
        }
      />
    </>
  );
};

export default FuelOptimzer;
