import Button from "components/button";
import Footer from "components/footer";
import Header from "components/header.js";
import Input from "components/input/input";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

export default function AllPosts() {
	const [loading, setLoading] = useState(true);
	const [blogPosts, setBlogPosts] = useState([]);
	const [search, setSearch] = useState("");
	const [searchLoading, setSearchLoading] = useState(false);

	const getBlogPosts = async () => {
		const post = await sanityClient.fetch(
			`*[_type == "post"]{
					title,
					slug,
					mainImage{
						asset->{
							_id,
							url
						}
					}
				}`
		);
		setBlogPosts(post);
		setLoading(false);
	};

	useEffect(() => {
		getBlogPosts();
	}, []);

	const handleSearchClick = async (e) => {
		e.preventDefault();

		setSearchLoading(true);

		if (!search.length) {
			getBlogPosts();
			setSearchLoading(false);
			return;
		}

		const searchTerms = search.split(" ");

		const titleFilter = searchTerms
			.reduce((result, term) => {
				return [...result, `title match "${term}"`];
			}, [])
			.join(" && ");

		const query = `*[ ${titleFilter} ]`;

		const post = await sanityClient.fetch(
			`${query} {
				title,
				slug,
				mainImage {
					asset -> {
						_id,
						url
					}
				}
			}`
		);

		setBlogPosts(post);
		setSearchLoading(false);
	};

	const handleSearchChange = (e) => setSearch(e.target.value);

	return (
		<>
			<Header type="orange" />
			<div className="max-w-screen-xl w-full mx-auto px-6 py-10">
				{/* Title and Search Bar */}
				<div className="mb-8 flex justify-between flex-col sm:flex-row gap-y-6 sm:gap-y-0">
					<div className="text-5xl font-bold text-gray-800">Blog</div>
					<form className="flex items-center">
						<Input
							type="text"
							placeholder="Search blogs"
							className="!mb-0 mr-4 w-72"
							onChange={handleSearchChange}
						/>
						<Button type="submit" onClick={handleSearchClick} className="w-max" loading={searchLoading}>
							Search
						</Button>
					</form>
				</div>

				{loading ? (
					<>
						<div
							alt=""
							className="h-96 p-8 flex flex-col justify-end rounded-lg mb-10 bg-gray-200 shadow-md"
						>
							<div className="skeleton-animation w-80 sm:w-[500px] h-10 rounded-xl mb-3"></div>
							<div className="skeleton-animation h-6 w-[250px] rounded-xl"></div>
						</div>
						<div className="grid gap-10 sm:grid-cols-4 sm:gap-4">
							{[1, 2, 3, 4].map((_, idx) => {
								return (
									<div key={idx}>
										<div className="skeleton-animation h-[197.328px] rounded-lg mb-4" />
										<div className="skeleton-animation h-7 w-56 rounded-xl mb-3"></div>
										<p className="skeleton-animation h-5 w-40 rounded-xl"></p>
									</div>
								);
							})}
						</div>
					</>
				) : (
					<>
						{blogPosts.length ? (
							<>
								{/* Large Post */}
								<Link
									to={`/blogs/${blogPosts[0]?.slug.current}`}
									style={{
										backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${blogPosts[0]?.mainImage.asset.url})`,
									}}
									alt=""
									className="h-96 bg-cover bg-no-repeat bg-center p-8 flex flex-col justify-end cursor-pointer rounded-lg mb-10"
								>
									<h2 className="text-white text-4xl sm:text-5xl font-bold mb-3">
										{blogPosts[0]?.title}
									</h2>
									<p className="text-white">
										By {blogPosts[0]?.name || "ifuelsmart"} | <span>February 16, 2022</span>
									</p>
								</Link>
								<div className="grid gap-10 sm:grid-cols-4 sm:gap-4">
									{blogPosts.map((post, idx) => {
										if (idx === 0) return null;
										return (
											<Link to={`/blogs/${post.slug.current}`} key={idx}>
												<img
													src={post.mainImage.asset.url}
													alt=""
													className="rounded-lg mb-4"
												/>
												<h2 className="text-xl font-bold mb-1">{post.title}</h2>
												<p className="text-sm">
													By {post.name || "ifuelsmart"} | <span>February 16, 2022</span>
												</p>
											</Link>
										);
									})}
								</div>
							</>
						) : (
							<>
								<p className="text-3xl font-semibold text-gray-600">No Post Found</p>
							</>
						)}
					</>
				)}
			</div>
			<Footer />
		</>
	);
}
