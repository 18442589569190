import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const Bot = () => {
	const { id } = useParams();

	return (
		<>
			<Helmet>
				{/* Luis */}
				{id === "1" && (
					<script>
						{
							'var BotStar={appId:"s08b22a70-21b3-11ed-988b-e3df967fb477",mode:"fullpage"};!function(t,a){var e=function(){(e.q=e.q||[]).push(arguments)};e.q=e.q||[],t.BotStarApi=e;!function(){var t=a.createElement("script");t.type="text/javascript",t.async=1,t.src="https://widget.botstar.com/static/js/widget.js";var e=a.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)}();}(window,document)'
						}
					</script>
				)}
				{/* Marco */}
				{id === "2" && (
					<script>
						{
							'var BotStar={appId:"s4b3d8db0-2467-11ed-988b-e3df967fb477",mode:"fullpage"};!function(t,a){var e=function(){(e.q=e.q||[]).push(arguments)};e.q=e.q||[],t.BotStarApi=e;!function(){var t=a.createElement("script");t.type="text/javascript",t.async=1,t.src="https://widget.botstar.com/static/js/widget.js";var e=a.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)}();}(window,document)'
						}
					</script>
				)}
				{/* Gisela */}
				{id === "3" && (
					<script>
						{
							'var BotStar={appId:"sbc07f120-16a7-11ed-988b-e3df967fb477",mode:"fullpage"};!function(t,a){var e=function(){(e.q=e.q||[]).push(arguments)};e.q=e.q||[],t.BotStarApi=e;!function(){var t=a.createElement("script");t.type="text/javascript",t.async=1,t.src="https://widget.botstar.com/static/js/widget.js";var e=a.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)}();}(window,document)'
						}
					</script>
				)}
			</Helmet>
		</>
	);
};

export default Bot;
