const FuelOptimizerLayout = ({ leftPanel, map }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-6 h-full overflow-y-auto no-scrollbar">
      <div className="w-full lg:max-w-sm bg-white shadow-lg rounded-sm border border-gray-200">{leftPanel}</div>
      <div className="bg-white shadow-lg rounded-sm border border-gray-200 flex-1">
        <div className="p-5 h-96 lg:h-full">{map}</div>
      </div>
    </div>
  );
};

export default FuelOptimizerLayout;
